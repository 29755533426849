<template>
  <div class="loufengSearch">
    <van-sticky>
      <van-search
        v-model="searchValue"
        shape="round"
        background="rgb(242,19,19)"
        left-icon=""
        @clear="clear"
        @input="input"
        placeholder="请输入服务...">
        <template #left-icon>
          <div class="back" @click="$router.go(-1)">
            <van-icon name="arrow-left" size="25" color="rgb(242,19,19)" />
          </div>
        </template>
        <template #right-icon>
          <div class="searchBtn" @click="search">
            <van-icon name="search" color="white" size="15" style="font-weight:800" />
          </div>
        </template>
      </van-search>
    </van-sticky>
    <div class="history" v-if="isSearch && lfSearchRecode.length > 0">
      <div class="history-title">
        <div>历史搜索</div>
        <div @click="delRecord">
          <van-icon name="delete-o" size="20" color="rgb(242,19,19)"></van-icon>
        </div>
      </div>
      <div class="history-record">
        <van-tag v-for="(item, index) in lfSearchRecode" :key="index" @click="search($event, item)" round plain type="success" size="large" style="margin: 3px 5px">
          {{ item }}
        </van-tag>
      </div>
    </div>
    <div class="main" v-if="!isSearch">
      <div class="result-title" v-if="!isSearch">
        为你找到" {{ searchValue }} "相关的楼凤信息
      </div>
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <!-- <template #pulling>
          <div></div>
        </template>
        <template #loosing>
          <div></div>
        </template> -->
        <template #loading>
          <div></div>
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="80"
          :immediate-check="false"
          @load="onLoad"
          v-if="!isNoData"
        >
          <div class="box">
            <div class="box-item" v-for="(item,index) in loufengData" :key="index" @click="jumpLouFengDetail(item.id)">
              <div class="infoBox">
                <div class="infobox-item payable" v-if="item.payable"></div>
                <div class="infobox-item top" v-if="item.topType === 9"></div>
                <div class="infobox-item boutique" v-if="item.topType === 8"></div>
                <div class="img">
                  <ImgDecypt fill="cover" :src="item.img" />
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="info">
                  <span>{{ item.height }}cm.{{ item.age }}.{{ item.cup }}</span>
                </div>
                <div class="price">
                  <span>价格：{{ item.srvPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <!-- 没有数据的时候展示 -->
        <NoData v-if="isNoData" class="noData"></NoData>
      </van-pull-refresh>
    </div>
    <!-- 整页加载 -->
    <van-overlay :show="firstLoading" z-index="101">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#f21313">数据加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getSearchList } from '@/api/loufeng.js';
import NoData from "@/components/NoData";

export default {
  name: 'loufengSearch',
  components: {
    NoData
  },
  data() {
    return {
      searchValue: '',
      lfSearchRecode: [],
      loufengData: [],
      pageNum: 1,
      pageSize: 10,
      isSearch: true,
      firstLoading: false,
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
    }
  },
  created() {
    this.getLfSearchRecode();
  },
  methods: {
    getLfSearchRecode() {
      let recode = localStorage.getItem('lfSearchRecode') ? localStorage.getItem('lfSearchRecode') : [];
      if(recode.length > 1) {
        this.lfSearchRecode = recode.split(',');
      }
    },
    /**
     * 1.点击按钮搜索；2.点击标签可搜索
     * 3.空数据是不能搜索的
    */
    search(e, params) {
      if(this.searchValue.trim() || params) {
        if(!this.searchValue) {
          this.searchValue = params;
        }
        // 进行搜索
        this.firstLoading = true;
        this.lfSearchRecode.push(this.searchValue);
        // 数组去重
        let tempArr = new Set(this.lfSearchRecode);
        this.lfSearchRecode = Array.from(tempArr);
        localStorage.setItem('lfSearchRecode', this.lfSearchRecode);
        this.loufengData = []; //  清空数据
        this.getSearchList(params); // 调用搜索接口
        this.isSearch = false;
      } else {
        this.$toast('请输入搜索内容');
      }
    },
    delRecord() {
      localStorage.removeItem('lfSearchRecode');
      this.lfSearchRecode = [];
      this.isSearch = true;
    },
    async getSearchList(params) {
      if(!this.searchValue) {
        this.searchValue = params;
      }
      let req = {
        content: this.searchValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      let res = await this.$Api(getSearchList, req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
      if(res && res.code === 200) {
        let loufengData = res.data.list;
        this.loufengData = this.loufengData.concat(loufengData);
        if (loufengData.length === 0 && this.pageNum == 1) {
          this.isNoData = true;
          this.finished = true;
          return;
        }
        if (res.data.list.length < this.pageSize) {
          this.finished = true;
        }
      } else {
        this.$toast('获取数据异常')
      }
    },
    // 数据加载
    onLoad() {
      this.pageNum++;
      this.getSearchList();
    },
    // 数据刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      this.firstLoading = true;
      this.loufengData = [];
      this.pageNum = 1;
      this.getSearchList();
    },
    // 去楼凤详情页
    jumpLouFengDetail(id) {
      this.$router.push({
        name: 'loufengDetail',
        params: {
          id: id
        }
      })
    },
    // 清除搜索
    clear() {
      this.isSearch = true;
    },
    // 监听值为空的时候，关闭搜索内容
    input(val) {
      if(!val) {
        this.isSearch = true;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .loufengSearch {
    .searchBtn {
      padding: 0 15px;
      background-color: rgb(242,19,19);
      border-radius: 15px;
    }
    .history {
      font-size: 15px;
      .history-title {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
      }
      .history-record {
        padding: 0 15px;
        
      }
    }
    .result-title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      font-size: 14px;
      background-color: white;
    }
    .main {
      padding: 0 10px;
      .box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .box-item {
          width: 49%;
          .infoBox{
            width: 100%;
            border-radius: 5px;
            box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.6);
            padding: 0 0;
            margin-bottom: 10px;
            position: relative;
            .img {
              width: 100%;
              text-align: center;
              height: 234px;
              /deep/ .van-image__img {
                border-radius: 5px 5px 0 0;
              }
            }
            .title {
              width: 150px;
              font-size: 13px;
              word-break:keep-all;
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
              margin: 4px 4px;
            }
            .info {
              width: 100%;
              font-size: 12px;
              color: rgba(0,0,0,0.6);
              margin: 4px 4px;
            }
            .price {
              width: 100%;
              font-size: 12px;
              color: red;
              margin-top: 4px;
              margin-left: 4px;
              height: 22px;
              word-break:keep-all;
              white-space:nowrap;
              overflow:hidden;
              text-overflow:ellipsis;
            }
            .infobox-item {
              height: 24px;
              width: 54px;
              position: absolute;
              z-index: 22;
              color: white;
              font-size: 13px;
              line-height: 24px;
              text-align: center;
              font-weight: 800;
            }
            // 可赔付
            .payable {
              left: 0px;
              top: 0px;
              background: url('../../../assets/png/payable.png') center center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 5px 5px 5px 0;
            }

            // 置顶
            .top {
              right: -7px;
              top: 5px;
              border-radius: 5px 5px 0 5px;
              background: url('../../../assets/png/top.png') center center;
              background-size: cover;
              background-repeat: no-repeat;
              transform: rotate(40deg);
            }

            // 精品
            .boutique {
              right: -7px;
              top: 5px;
              border-radius: 5px 5px 0 5px;
              background: url('../../../assets/png/boutique.png') center center;
              background-size: cover;
              background-repeat: no-repeat;
              transform: rotate(40deg);
            }
          }
        }
      }
      .noData {
        height: calc(100vh - 150px);
      }
      // 底部没有更多了
      /deep/ .van-list__finished-text {
        line-height: 30px;
        margin-bottom: 10px;
      }
      // 下拉loading
      /deep/ .van-loading {
        height: 100px;
      }
    }
  }
  .all-content {
    height: unset;
  }
  // 整页加载
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;
  }
  /deep/ .van-loading--spinner {
    margin-top: unset;
  }
</style>
